<template>
  <div class="box">
    <el-tabs v-model="tabIdx">
      <el-tab-pane label="协议设置" name="1"></el-tab-pane>
      <el-tab-pane label="规则设置" name="2"></el-tab-pane>
    </el-tabs>
    <el-form :model="info" label-position="right" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="用户协议" prop="agreement_user" v-show="tabIdx==1">
        <wang-edit :txt="info.agreement_user" :callback="setText" :idx="1"></wang-edit>
      </el-form-item>
      <el-form-item label="隐私协议" prop="agreement_secret" v-show="tabIdx==1">
        <wang-edit :txt="info.agreement_secret" :callback="setText" :idx="2"></wang-edit>
      </el-form-item>
      <el-form-item label="推客协议" prop="agreement_guider" v-show="tabIdx==1">
        <wang-edit :txt="info.agreement_guider" :callback="setText" :idx="6"></wang-edit>
      </el-form-item>
      <el-form-item label="充值规则" prop="recharge_rule" v-show="tabIdx==2">
        <wang-edit :txt="info.recharge_rule" :callback="setText" :idx="3"></wang-edit>
      </el-form-item>
      <el-form-item label="积分规则" prop="integral_rule" v-show="tabIdx==2">
        <wang-edit :txt="info.integral_rule" :callback="setText" :idx="4"></wang-edit>
      </el-form-item>
      <el-form-item label="关于我们" prop="about_rule" v-show="tabIdx==2">
        <wang-edit :txt="info.about_rule" :callback="setText" :idx="5"></wang-edit>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import WangEdit from "../../components/WangEdit";
  export default {
    data() {
      return {
        tabIdx: '1',
        info: {
          agreement_user: '',
          agreement_secret: '',
          agreement_guider: '',
          recharge_rule: '',
          integral_rule: '',
          about_rule: ''
        }
      };
    },
    components: {
      WangEdit
    },
    created() {
      this.getInfo()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      setText(txt,idx) {
        if(idx==1) {
          this.info.agreement_user = txt
        } else if(idx==2) {
          this.info.agreement_secret = txt
        } else if(idx==3) {
          this.info.recharge_rule = txt
        } else if(idx==4) {
          this.info.integral_rule = txt
        } else if(idx==5) {
          this.info.about_rule = txt
        } else if(idx==6) {
          this.info.agreement_guider = txt
        }
      },
      getInfo() {
        var _this = this
        this.$api.merchant.secretInfo( {},function (res) {
          if(res.errcode == 0) {
            _this.info = res.data
          }
        })
      },
      submitForm(formName) {
        var _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = _this.info
            this.$api.merchant.secretSave(param,function (res) {
              if(res.errcode == 0) {
                _this.success(res.errmsg)
              } else {
                _this.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 900px;
  }
</style>
